/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../Polyline.js";
import n from "../SpatialReference.js";
import { isPolygon as t } from "./jsonUtils.js";
const r = {
  102100: {
    maxX: 20037508.342788905,
    minX: -20037508.342788905,
    plus180Line: new e({
      paths: [[[20037508.342788905, -20037508.342788905], [20037508.342788905, 20037508.342788905]]],
      spatialReference: n.WebMercator
    }),
    minus180Line: new e({
      paths: [[[-20037508.342788905, -20037508.342788905], [-20037508.342788905, 20037508.342788905]]],
      spatialReference: n.WebMercator
    })
  },
  4326: {
    maxX: 180,
    minX: -180,
    plus180Line: new e({
      paths: [[[180, -180], [180, 180]]],
      spatialReference: n.WGS84
    }),
    minus180Line: new e({
      paths: [[[-180, -180], [-180, 180]]],
      spatialReference: n.WGS84
    })
  }
};
function i(e, n) {
  return Math.ceil((e - n) / (2 * n));
}
function s(e, n) {
  const t = o(e);
  for (const r of t) for (const e of r) e[0] += n;
  return e;
}
function o(e) {
  return t(e) ? e.rings : e.paths;
}
export { r as cutParams, o as getGeometryParts, i as offsetMagnitude, s as updatePolyGeometry };